import { GET_REPORTS } from "../actions/report.action";

const initialState = {
    reports: [],
};

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORTS:
            return {
                ...state,
                reports: action.payload,
            };
        default:
            return state;
    }

};

export default reportReducer;


