import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS, USER_SET, SET_CURRENT_COMPANY, SET_USER_COMPANIES } from '../actions/auth.action.js';

const initialState = {
  user: null,
  error: null,
  company: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null
      };
    case USER_SET:
      return {
        ...state,
        user: action.payload,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        error: action.payload
      };
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        company: action.payload
      };
    case SET_USER_COMPANIES:
      return {
        ...state,
        userCompanies: action.payload
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        error: null,
        company: null
      };
    default:
      return state;
  }
};

export default userReducer;