import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import userReducer from "./redux/reducer/user.reducer";
import { thunk } from "redux-thunk";
import departmentReducer from "./redux/reducer/department.reducer";
import themeReducer from "./redux/reducer/theme.reducer";
import propertyReducer from "./redux/reducer/property.reducer";
import reportReducer from "./redux/reducer/report.reducer";
import ticketReducer from "./redux/reducer/ticket.reducer";
import associateReducer from "./redux/reducer/associate.reducer";
import messageReducer from "./redux/reducer/message.reducer";
import scheduleReducer from "./redux/reducer/schedule.reducer";
import alertReducer from "./redux/reducer/alert.reducer";

const initialState = {
  sidebarShow: true,
  theme: "light",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  sidebar: changeState,
  user: userReducer,
  department: departmentReducer,
  theme: themeReducer,
  property: propertyReducer,
  report: reportReducer,
  ticket: ticketReducer,
  associate: associateReducer,
  message: messageReducer,
  schedule: scheduleReducer,
  alert: alertReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
