import { SET_THEME } from "../actions/theme.action";

const initialState = {
  theme: 'light',
};

const themeReducer = (state = initialState, action) => {
  // console.log(action, "action");
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
};

export default themeReducer;
