import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import auth from "src/firebase";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  USER_SET,
  SET_CURRENT_COMPANY,
  SET_USER_COMPANIES,
} from "../actions/auth.action.js";

export const createUser = (email, password, displayName) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          if (displayName) {
            await updateProfile(userCredential.user, {
              displayName: displayName,
            });
          }
          dispatch({ type: LOGIN_SUCCESS, payload: userCredential.user });
          localStorage.setItem("user", JSON.stringify(userCredential.user));
          localStorage.setItem(
            "accessToken",
            JSON.stringify(userCredential.user.accessToken)
          );
          resolve(userCredential);
        })
        .catch((error) => {
          dispatch({ type: LOGIN_FAILURE, payload: error.message });
          reject(error);
        });
    });
};

export const setUser = (user) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: USER_SET, payload: user });
      resolve(user);
    });
};

export const setCurrentCompany = (company) => {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: SET_CURRENT_COMPANY, payload: company });
      localStorage.setItem("currentCompany", JSON.stringify(company));
      resolve(company);
    });
};

export const setUserCompanies = (companies) => {
  return (dispatch) =>
    new Promise((resolve) => {
      const currentCompany = JSON.parse(localStorage.getItem("currentCompany"));

      if (companies.length > 0) {
        console.log("1")
        const currentCompany = JSON.parse(localStorage.getItem("currentCompany"));
        if (currentCompany) {
          const selectedCompany = companies.find(
            (company) => company.id === currentCompany.id
          );
          console.log("2", selectedCompany);
          dispatch({ type: SET_CURRENT_COMPANY, payload: selectedCompany || companies[0] });
          localStorage.setItem("currentCompany", JSON.stringify(selectedCompany || companies[0]));
        } else {
          dispatch({ type: SET_CURRENT_COMPANY, payload: companies[0] });
          localStorage.setItem("currentCompany", JSON.stringify(companies[0]));
        }
      } else {
        dispatch({ type: SET_CURRENT_COMPANY, payload: null });
        localStorage.removeItem("currentCompany");
      }

      dispatch({ type: SET_USER_COMPANIES, payload: companies });
      resolve(companies);
    });
}

export const login = (email, password) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          console.log(userCredential);
          dispatch({ type: LOGIN_SUCCESS, payload: userCredential.user });
          localStorage.setItem("user", JSON.stringify(userCredential.user));
          localStorage.setItem(
            "accessToken",
            JSON.stringify(userCredential.user.accessToken)
          );
          resolve(userCredential);
        })
        .catch((error) => {
          console.log("ERROR LOGIN", error);
          dispatch({ type: LOGIN_FAILURE, payload: error.message });
          reject(error);
        });
    });
};

export const logout = () => {
  return (dispatch) => new Promise((resolve) => {
    signOut(auth).then(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      dispatch({ type: LOGOUT_SUCCESS });
      resolve();
    });
  });
};
