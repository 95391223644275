import { ADD_DEPARTMENT, DELETE_DEPARTMENT, REFRESH_DEPARTMENTS, UPDATE_DEPARTMENT } from "../actions/department.action";

const initialState = {
  departments: [],
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_DEPARTMENTS:
      return {
        ...state,
        departments: action.departments,
      };
    case ADD_DEPARTMENT:
      return {
        ...state,
        departments: [...state.departments, action.department],
      };
    case UPDATE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.map((department) =>
          department.id === action.department.id ? action.department : department
        ),
      };
    case DELETE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.filter((department) => department.id !== action.departmentId),
      };
    default:
      return state;
  }
};

export default departmentReducer;
