// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  GoogleAuthProvider,
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithPopup,
} from "firebase/auth";

import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


let firebaseConfig = null;
let apiDomain = null;

console.log("REACT_APP_ENV 12 3", process.env.REACT_APP_ENV)
if (process.env.REACT_APP_ENV === "live") {
  console.log("Production")
  apiDomain = "https://us-central1-blue-beagle-live.cloudfunctions.net";
  firebaseConfig = {
    apiKey: "AIzaSyAjeXczw4OLklaJNKFXaX-41ve53dnmRho",
    authDomain: "blue-beagle-live.firebaseapp.com",
    databaseURL: "https://blue-beagle-live-default-rtdb.firebaseio.com",
    projectId: "blue-beagle-live",
    storageBucket: "blue-beagle-live.appspot.com",
    messagingSenderId: "946118573334",
    appId: "1:946118573334:web:c54c2257a43e81f28da437",
    measurementId: "G-GJJHR5H531",
  };
} else {
  console.log("Development")
  apiDomain = "https://us-central1-blue-beagle-development.cloudfunctions.net";
  firebaseConfig = {
    apiKey: "AIzaSyCx8Hrt8Sn-D-BxYGkRGPoyv8PLOf2E10g",
    authDomain: "blue-beagle-development.firebaseapp.com",
    databaseURL: "https://blue-beagle-development-default-rtdb.firebaseio.com",
    projectId: "blue-beagle-development",
    storageBucket: "blue-beagle-development.appspot.com",
    messagingSenderId: "155344600831",
    appId: "1:155344600831:web:5a314f35c2ccfdc72316ca",
    measurementId: "G-PF9D9WFJPS",
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const storage = getStorage(app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Persistence set successfully
    // console.log("Persistence set successfully");
  })
  .catch((error) => {
    // Handle errors
    console.error("Error setting persistence:", error);
  });
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account ",
});
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
const functions = getFunctions(app);
// USER
export const getUser = httpsCallable(functions, "getUser");
export const getUserCompanies = httpsCallable(functions, "getUserCompanies");
export const updateCompany = httpsCallable(functions, "updateCompany");
// DASHBOARD
export const dashboardSummary = httpsCallable(functions, "dashboardSummary");
//DEPARTMENT
export const getDepartments = httpsCallable(functions, "getDepartments");
export const createDepartment = httpsCallable(functions, "createDepartment");
export const deleteDepartment = httpsCallable(functions, "deleteDepartment");
export const updateDepartment = httpsCallable(functions, "updateDepartment");
// PROPERTY
export const getProperties = httpsCallable(functions, "getProperties");
export const createProperty = httpsCallable(functions, "createProperty");
export const updateProperty = httpsCallable(functions, "updateProperty");
export const deleteProperty = httpsCallable(functions, "deleteProperty");
export const completePropertyImageUpload = httpsCallable(functions, "completePropertyImageUpload");
export const cloneProperty = httpsCallable(functions, "cloneProperty");

// AREAKEYS
export const getAreaKeys = httpsCallable(functions, "getAreaKeys");
export const createAreaKey = httpsCallable(functions, "createAreaKey");
export const updateAreaKey = httpsCallable(functions, "updateAreaKey");
export const deleteAreaKey = httpsCallable(functions, "deleteAreaKey");

// TICKETS
export const getTickets = httpsCallable(functions, "getTickets");
export const getTicket = httpsCallable(functions, "getTicket");
export const createTicket = httpsCallable(functions, "createTicket");
export const updateTicket = httpsCallable(functions, "updateTicket");
export const getTicketActivities = httpsCallable(functions, "getTicketActivities");

// ASSOCIATES
export const getAssociates = httpsCallable(functions, "getAssociates");
export const createAssociate = httpsCallable(functions, "createAssociate");
export const updateAssociate = httpsCallable(functions, "updateAssociate");
export const deleteAssociate = httpsCallable(functions, "deleteAssociate");
// LOCATIONS
export const liveLocations = httpsCallable(functions, "liveLocations");

// SCHEDULES
export const getSchedules = httpsCallable(functions, "getSchedules");
export const createSchedule = httpsCallable(functions, "createSchedule");
export const updateSchedule = httpsCallable(functions, "updateSchedule");
export const deleteSchedule = httpsCallable(functions, "deleteSchedule");
export const generateScheduleSummaryPDF = httpsCallable(functions, "generateScheduleSummaryPDF");
export const cloneSchedule = httpsCallable(functions, "cloneSchedule");
export const moveSchedule = httpsCallable(functions, "moveSchedule");
export const getAreas = httpsCallable(functions, "getAreas");
export const getTasks = httpsCallable(functions, "getTasks");

export default auth;

// SCHEDULE SUMMARIES
export const getScheduleSummaries = httpsCallable(functions, "getScheduleSummaries");

// API
export const firebaseAPIDomain = apiDomain;