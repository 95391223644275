import { ADD_MESSAGE, DELETE_MESSAGE, GET_ALL_MESSAGES, UPDATE_MESSAGE } from "../actions/message.action";

const initialState = {
  messages: [],
};

const messageReducer = (state = initialState, action) => {
  // console.log(action, "action");
  switch (action.type) {
    case GET_ALL_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        messages: [...action.payload],
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        messages: [...action.payload],
      };
    default:
      return state;
  }
};

export default messageReducer;
