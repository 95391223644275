import { ADD_PROPERTY, DELETE_PROPERTY, REFRESH_PROPERTIES, UPDATE_PROPERTY } from "../actions/property.action";

const initialState = {
  properties: [],
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
      };
    case ADD_PROPERTY:
      return {
        ...state,
        properties: [...state.properties, action.property],
      };
    case UPDATE_PROPERTY:
      return {
        ...state,
        properties: state.properties.map((property) =>
          property.id === action.property.id ? action.property : property
        ),
      };
    case DELETE_PROPERTY:
      return {
        ...state,
        properties: state.properties.filter((property) => property.id !== action.propertyId),
      };
    default:
      return state;
  }
};

export default propertyReducer;
