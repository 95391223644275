import { UPDATE_ALERT } from "../actions/alert.action";

const initialState = {
  alert: {
    visible: false,
    message: "Placeholder message",
    color: "success",
  },
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ALERT:
      return {
        ...state,
        alert: action.alert,
      };
    default:
      return state;
  }
}

export default alertReducer;