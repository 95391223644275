import React from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { setUser } from "src/redux/service/user.service";
const ProtectedRoute = ({ children }) => {
  const authUser = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  if (authUser) {
    dispatch(setUser(authUser));
    return children;
  }

  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
