import {
  ADD_SCHEDULE,
  DELETE_SCHEDULE,
  GET_ALL_SCHEDULES,
  SET_SCHEDULES,
  UPDATE_SCHEDULE,
  UPDATE_SCHEDULES,
} from "../actions/schedule.action";

const initialState = {
  schedules: {},
};

const scheduleReducer = (state = initialState, action) => {
  // console.log(action, "action");
  switch (action.type) {
    case GET_ALL_SCHEDULES:
      return {
        ...state,
        schedules: {
          ...state.schedules,
          ...action.payload,
        },
      };
    case ADD_SCHEDULE:
      return {
        ...state,
        schedules: [...state.schedules, action.payload],
      };
    case UPDATE_SCHEDULE:
      return {
        ...state,
        schedules: [...action.payload],
      };
    case DELETE_SCHEDULE:
      return {
        ...state,
        schedules: [...action.payload],
      };
    case UPDATE_SCHEDULES:
      return {
        ...state,
        schedules: [...action.payload],
      };
    case SET_SCHEDULES:
      return {
        ...state,
        schedules: { ...action.payload },
      };
    default:
      return state;
  }
};

export default scheduleReducer;
