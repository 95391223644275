import { ADD_ASSOCIATE, DELETE_ASSOCIATE, REFRESH_ASSOCIATES, UPDATE_ASSOCIATE } from "../actions/associate.action";

const initialState = {
  associates: [],
};

const associateReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_ASSOCIATES:
      return {
        ...state,
        associates: action.associates,
      };
    case ADD_ASSOCIATE:
      return {
        ...state,
        associates: [...state.associates, action.associate],
      };
    case UPDATE_ASSOCIATE:
      return {
        ...state,
        associates: state.associates.map((associate) =>
          associate.id === action.associate.id ? action.associate : associate
        ),
      };
    case DELETE_ASSOCIATE:
      return {
        ...state,
        associates: state.associates.filter((associate) => associate.id !== action.associateId),
      };
    default:
      return state;
  }
};

export default associateReducer;
