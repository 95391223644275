import { ADD_TICKET, DELETE_TICKET, GET_ALL_TICKETS, UPDATE_TICKET } from "../actions/ticket.action";

const initialState = {
  tickets: [],
};

const ticketReducer = (state = initialState, action) => {
  // console.log(action, "action");
  switch (action.type) {
    case GET_ALL_TICKETS:
      return {
        ...state,
        tickets: action.payload,
      };
    case ADD_TICKET:
      return {
        ...state,
        tickets: [...state.tickets, action.payload],
      };
    case UPDATE_TICKET:
      return {
        ...state,
        tickets: [...action.payload],
      };
    case DELETE_TICKET:
      return {
        ...state,
        tickets: [...action.payload],
      };
    default:
      return state;
  }
};

export default ticketReducer;
